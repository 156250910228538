import React, { useState } from "react"
import styled from "styled-components"
import LogoIcon from "../images/LogoIcon.component"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import Img from "../images/loginBg.png"
import axios from "axios"
import { navigate } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import useAuthState from "../stores/auth"

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: "flex",
    alignContent: "center",
    // backgroundImage: `url(${Img})`,
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    backgroundColor: "#4ddde0",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  signin: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
    },
  },
  card: {
    flex: 1,

    borderRadius: "10px",
    boxShadow: "0px 10px 20px #a3d4cb",

    padding: "20px",
  },
  header: {
    textAlign: "center",
  },
  body: {},
  button: {
    marginTop: "10px",
    textAlign: "center",
  },
  icon: {
    justifySelf: "left",
  },
  register: {
    marginTop: "5px",
    color: "#1d9682",
  },
  link: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  google: {
    width: "100%",
    padding: "15px 0",
    display: "flex",
    justifyContent: "center",
  },
  google__button: {
    padding: "6px 16px",
    border: "1px solid #a3d4cb",
    borderRadius: "3px",
    fontWeight: 600,
    lineHeight: 1.75,
    width: "60%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  googleIcon: {
    width: "100px",
    height: "40px",
  },
  input: {
    padding: " 20px 0 ",

    "& :valid": {
      color: "#1d9682",
    },
    "& :invalid": {
      color: "red",
    },
  },
}))

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#a3d4cb",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#a3d4cb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#a3d4cb",
      },
    },
  },
})(TextField)

const ResetPasswordBtn = styled(Button)`
  background: #1d9682;
  width: 100%;
  box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  white-space: nowrap;
  padding: 10px 22px;
  color: white;
  font-family: "Oswald";
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: "center";
  align-items: center;

  &:hover {
    transition: all 0.2sease-in-out;
    background: #f5f5f5;
    color: #205072;
  }
`

export default function Reset() {
  const classes = useStyles()

  const [token] = useQueryParam("token", StringParam)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")

  const { logIn } = useAuthState()

  const handleResetPassword = async () => {
    setError("")
    setSuccess("")
    if (!password || !confirm) {
      return setError("All field are required")
    }
    if (password.length < 6) {
      return setError("New password must be at least 6 characters")
    }
    if (password !== confirm) {
      return setError("Password and confirm password do not match")
    } else {
      try {
        setIsLoading(true)

        const result = await axios.post(
          `${process.env.GATSBY_API_URL}/users/reset-password`,
          { password, confirm, token }
        )

        setIsLoading(false)
        setSuccess(result.data.message)
        logIn(result.data)
        navigate("/pharmacy")
      } catch (error) {
        setIsLoading(false)
        const message = error.response.data.message
        setError(message)
      }
    }
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Paper className={classes.signin}>
          <Grid className={classes.card}>
            <Grid className={classes.header}>
              <Link to="/">
                <LogoIcon className={classes.icon} />
              </Link>
              <Typography
                align="center"
                variant="h5"
                style={{
                  margin: "10px 0",
                  color: "#595959",
                  fontFamily: "Oswald",
                }}
              >
                Reset Password
              </Typography>
            </Grid>
            <Grid className={classes.body}>
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              <p style={{ color: "green", textAlign: "center" }}>{success}</p>
              <form style={{ padding: "50px 0" }}>
                <CssTextField
                  label="New password"
                  name="password"
                  className={classes.input}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  autoComplete="off"
                  required
                  inputProps={{ minlength: 6 }}
                  type="password"
                  fullWidth
                />

                <CssTextField
                  label="Confirm new password"
                  autoComplete="off"
                  className={classes.input}
                  name="confirm"
                  value={confirm}
                  onChange={e => setConfirm(e.target.value)}
                  required
                  inputProps={{ minlength: 6 }}
                  type="password"
                  fullWidth
                />

                <ResetPasswordBtn
                  onClick={() => handleResetPassword()}
                  fullWidth
                  className={classes.button}
                  variant="contained"
                  disabled={!password || !confirm || isLoading}
                >
                  Reset Password
                </ResetPasswordBtn>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}
